import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useNavigate } from 'react-router-dom'

const DrawerContent = () => {
    const navigate = useNavigate();

    return <>
        <div>
            <Toolbar>
                <Link to={'/'}>Positivehotel</Link>
            </Toolbar>
            <Divider />
            <ListItem key={'dashboard'} disablePadding>
                <ListItemButton onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'DASHBOARD'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'positBotHistory'} disablePadding>
                <ListItemButton onClick={() => navigate('/positbot/history')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'BOT'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'positBotOrder'} disablePadding>
                <ListItemButton onClick={() => navigate('/positbot/order')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'ORDER'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'positBotBuyer'} disablePadding>
                <ListItemButton onClick={() => navigate('/positbot/buyer')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'BUYER'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'customer'} disablePadding>
                <ListItemButton onClick={() => navigate('/positbot/customer')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'CUSTOMER'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'positBotProduct'} disablePadding>
                <ListItemButton onClick={() => navigate('/positbot/product')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'PRODUCT'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'positBotReport'} disablePadding>
                <ListItemButton onClick={() => navigate('/positbot/report')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'REPORT'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'goodsMapping'} disablePadding>
                <ListItemButton onClick={() => navigate('/goods-mapping')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'GOODS'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'buyReport'} disablePadding>
                <ListItemButton onClick={() => navigate('/buy-report')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'BUY_REPORT'} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'데이터 수집'} disablePadding>
                <ListItemButton onClick={() => navigate('/v2')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'데이터 수집'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'제품 판매 통계'} disablePadding>
                <ListItemButton onClick={() => navigate('/sales/product')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'제품 판매 통계'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'프로모션 통계'} disablePadding>
                <ListItemButton onClick={() => navigate('/cafe24/event')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'프로모션 통계'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'리뷰 분석'} disablePadding>
                <ListItemButton onClick={() => navigate('/review-report')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'리뷰 분석'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'카카오챗봇'} disablePadding>
                <ListItemButton onClick={() => navigate('/kakao-chatbot/review-event')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'카카오챗봇'} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'Partner'} disablePadding>
                <ListItemButton onClick={() => navigate('/partner')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Partner'} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'KakaoPay'} disablePadding>
                <ListItemButton onClick={() => navigate('/kakaopay')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'KakaoPay'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'KakaoAlimTalk'} disablePadding>
                <ListItemButton onClick={() => navigate('/kakaoalimtalk')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'KakaoAlimTalk'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'cafe24'} disablePadding>
                <ListItemButton onClick={() => navigate('/cafe24')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Cafe24'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'naverCommerce'} disablePadding>
                <ListItemButton onClick={() => navigate('/naverCommerce')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'네이버 스토어'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'kakaoLogin'} disablePadding>
                <ListItemButton onClick={() => navigate('/kakaologin')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'카카오 로그인'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'naverLogin'} disablePadding>
                <ListItemButton onClick={() => navigate('/naverlogin')}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={'네이버 로그인'} />
                </ListItemButton>
            </ListItem>
        </div>
    </>
}

export default DrawerContent;